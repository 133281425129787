<!-- eslint-disable vue/valid-v-else-if -->
<template>
  <div class="table-container">
    <el-row style="margin-bottom: 10px" :span="12">
      <el-button
        icon="el-icon-printer"
        type="primary"
        size="mini"
        @click="handlePrintFunc"
      >
        批量打印发票
      </el-button>
      <el-button
        icon="el-icon-document-checked"
        size="mini"
        @click="rushBackFunc"
      >
        确认已入账发票冲回
      </el-button>
      <el-button icon="el-icon-download" size="mini" @click="exportExcelFunc">
        导出EXCEL
      </el-button>
    </el-row>

    <el-table
      ref="tableRef"
      border
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :header-cell-style="{
        background: '#f0f2f5',
        color: '#333',
        height: '5px',
      }"
      size="mini"
      :height="590 + 'px'"
      @selection-change="setSelectedRows"
    >
      <template slot="empty">
        <el-empty :image-size="120" :image="img"></el-empty>
      </template>
      <el-table-column
        fixed="left"
        align="center"
        show-overflow-tooltip
        type="selection"
      ></el-table-column>
      <el-table-column
        fixed="left"
        align="center"
        width="50"
        show-overflow-tooltip
        type="index"
        label="序号"
      ></el-table-column>
      <el-table-column
        v-for="(col, index) in colums"
        :key="index"
        :type="col.type"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :fixed="col.fixed"
        show-overflow-tooltip
      >
        <!-- 通过 scoped slots 自定义单元格内容 -->
        <template v-slot="scope">
          <span v-if="col.prop === 'invoiceLine'">
            {{ formatColumn(scope.row, col.prop) }}
          </span>
          <el-tag
            v-else-if="col.prop === 'printFlag'"
            :type="getPrintTagType(scope.row[col.prop])"
          >
            {{ scope.row[col.prop] === "0" ? "未打印" : "已打印" }}
          </el-tag>
          <span v-else>
            {{ scope.row[col.prop] != null ? scope.row[col.prop] : "-" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>

    <!-- 隐藏的 iframe 用于加载 PDF -->
    <iframe :src="pdfUrl" style="display: none" ref="iframe"></iframe>
  </div>
</template>

<script>
const invoiceLineEnum = {
  p: "普通发票（电票）",
  c: "普通发票（纸质）",
  s: "专用发票（纸质）",
  b: "专用发票（电子）",
  bs: "数电专票（电子）",
  pc: "数电普票（电子）",
  es: "数电专票（纸质）",
  ec: "数电普票（纸质）",
};

export default {
  name: "HisRecTableList",
  components: {},
  data() {
    return {
      img: require("../../assets/images/empty.png"),
      list: null,
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectedRows: [], // 存储勾选的表格数据
      elementLoadingText: "正在加载...",
      queryForm: {
        pageNo: 1,
        pageSize: 20,
      },

      colums: [
        { prop: "supplier", label: "供应商代码" },
        { prop: "sellerName", label: "供应商名称" },
        // { prop: "elecInvoiceNumber", label: "数电发票号码", width: 120 },
        { prop: "invoiceLine", label: "发票种类" },
        { prop: "invoiceCode", label: "发票代码" },
        { prop: "invoiceNumber", label: "发票号码", width: 170 },
        { prop: "invoice", label: "订单号" },
        { prop: "grnQty", label: "数量" },
        { prop: "grnValue", label: "未税金额", align: "right" },
        { prop: "journal", label: "journal号" },
        { prop: "strJnlDate", label: "Syspro发票录入日期" },
        { prop: "printFlag", label: "打印标志", align: "center" },
      ],
      userId: "",
      pdfUrl: "", // 用于加载PDF的iframe地址
    };
  },

  created() {
    // console.log("链接带过来的query参数：", this.$route.query);
    // console.log("当前页路径：", window.location.href);
    // console.log("userId：", this.$route.query);
    this.userId = this.$route.query.userId;
  },

  updated() {
    this.$nextTick(() => {
      this.$refs["tableRef"].doLayout();
    });
  },

  methods: {
    /**
     * @description 监听表格勾选变化
     */
    setSelectedRows(val) {
      this.selectedRows = val;
    },

    /**
     * @description 获取列表数据
     */
    async fetchData(searchData) {
      this.listLoading = true;
      var params = Object.assign({ ...this.queryForm, ...searchData });

      params.userId = this.userId;
      this.$api
        .post("/input/history/query", params)
        .then((res) => {
          console.log("res", res);
          this.listLoading = false;
          this.list = res.data;
          this.total = res.total;
        })
        .catch((err) => {
          this.$message.error(err.message);
          console.log("err", err);
          this.listLoading = false;
        });
    },

    /**
     * @description 改变列表每页显示条数
     */
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      const { searchData } = this.$store.state.HistoricalRecord;
      this.fetchData(searchData);
    },

    /**
     * @description 改变列表页数
     */
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      const { searchData } = this.$store.state.HistoricalRecord;
      this.fetchData(searchData);
    },

    /**
     * @description 格式化列表内容
     */
    formatColumn(row, column) {
      if (column === "invoiceLine") {
        return invoiceLineEnum[row.invoiceLine]; // 根据枚举映射显示
      }
      return row[column]; // 默认返回原始值
    },

    /**
     * @description 根据 printFlag 的状态返回不同的标签类型
     */
    getPrintTagType(value) {
      // console.log("根据 printFlag 的状态返回不同的标签类型", value);
      return value === "0" ? "" : "success";
    },

    /**
     * @description 批量打印发票
     */
    async handlePrintFunc() {
      if (this.selectedRows.length === 0) {
        this.$message.warning("请先选择一条数据！");
        return;
      }

      try {
        let params = {
          ids: this.selectedRows.map((row) => row.id), // 提取勾选数据中的id
          userId: this.userId,
        };
        // 发起请求
        const res = await this.$api.post(
          "/input/history/batchPDFPrinting",
          params
        );
        // 更新 pdfUrl
        const pdfUrl = res.data;
        // 在新窗口中打开 PDF
        const newWindow = window.open(pdfUrl);

        // 等待新窗口加载完成后触发打印
        if (newWindow) {
          newWindow.onload = () => {
            newWindow.print(); // 自动调用新窗口的打印功能
          };
        } else {
          this.$message.error("无法打开新窗口，请检查浏览器是否阻止弹窗。");
        }

        // 获取 iframe 引用
        // const iframe = this.$refs.iframe;

        // window.open(this.pdfUrl); // 打开 PDF 文件，让用户自己调用浏览器的打印

        // 检查 iframe 是否存在
        // if (iframe) {
        //   // 监听 iframe 的 load 事件，确保 PDF 加载完成后再调用打印
        //   iframe.onload = () => {
        //     iframe.contentWindow.focus(); // 聚焦 iframe
        //     iframe.contentWindow.print(); // 调用浏览器打印功能
        //   };
        //   // 设置 iframe 的 src 属性，加载 PDF
        //   iframe.src = this.pdfUrl;
        // } else {
        //   throw new Error("Iframe not found");
        // }
      } catch (err) {
        // 打印错误信息
        this.$message.error(err.message || "打印失败");
      }
    },

    /**
     * @description 确认已入账发票冲回
     */
    rushBackFunc() {
      if (this.selectedRows.length === 0) {
        this.$message.warning("请先选择一条数据！");
        return;
      }

      // 提取勾选数据中的id

      let params = {
        ids: this.selectedRows.map((row) => row.id),
        userId: this.userId,
      };
      this.$api
        .post("/input/history/invoiceReversal", params)
        .then((res) => {
          this.$message.success(res.message);
          this.queryForm = { pageNo: 1, pageSize: 20 };
          const { searchData } = this.$store.state.HistoricalRecord;
          this.fetchData(searchData);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /**
     * @description 导出EXCEL
     */
    exportExcelFunc() {
      // const { searchData } = this.$store.state.HistoricalRecord;

      const params = {
        ids: this.selectedRows.map((row) => row.id),
        userId: this.userId,
      };
      // 创建一个隐藏的表单
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "/input/history/invoiceExcelExport"; // API 端点
      form.target = "_blank"; // 新页面打开

      // 将参数添加到表单中
      Object.keys(params).forEach((key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = params[key];
        form.appendChild(input);
      });

      // 将表单添加到文档并提交
      document.body.appendChild(form);
      form.submit();

      // 移除表单
      document.body.removeChild(form);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;

  .right-title {
    margin: 12px 10px 0 0;

    .sumTotal {
      font-size: 14px;
      font-weight: bold;
      color: #da5536;
    }
  }
}
::v-deep {
  .el-table__empty-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-right: 100%;
  }
}
</style>
