<template>
  <div class="table-container">
    <el-row style="margin-bottom: 10px" :span="24">
      <el-col :span="20">
        <el-button
          icon="el-icon-collection-tag"
          type="primary"
          size="mini"
          @click="tempStorageFunc(1)"
        >
          暂存
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          size="mini"
          @click="tempStorageFunc(0)"
        >
          取消暂存
        </el-button>
      </el-col>
      <el-col :span="4" class="table-content-right">
        <el-popover placement="right" trigger="click" width="">
          <el-checkbox-group v-model="checkList">
            <template v-for="(item, i) in checkLabels">
              <el-checkbox
                :key="i"
                :label="item.prop"
                style="display: block; margin: 10px"
                @change="handlerFilterFunc('filter', item)"
              >
                {{ item.label }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
          <!-- 控制按钮 -->
          <el-button slot="reference" type="text" size="mini">
            <i class="el-icon-arrow-down el-icon-menu" /> 列筛选
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handlerFilterFunc('allChecked')"
          >
            全选
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handlerFilterFunc('cancel')"
          >
            取消全选
          </el-button>
        </el-popover>
      </el-col>
    </el-row>

    <el-alert
      style="margin-bottom: 10px"
      type="warning"
      show-icon
      :closable="false"
    >
      <template #title>
        已选GRN数量：
        <span style="color: #fd6e0d; margin-right: 3px">
          {{ selectedRowCount }} </span
        >条 ， 匹配数量合计：
        <span style="color: #fd6e0d; margin-right: 3px">
          {{ totals.matchingNumber }} </span
        >个 ， 匹配金额合计：
        <span style="color: #fd6e0d; margin-right: 3px">
          {{ totals.matchingAmount }} </span
        >元
      </template>
    </el-alert>

    <el-table
      ref="grnTableRef"
      v-loading="listLoading"
      border
      :data="list"
      :element-loading-text="elementLoadingText"
      :header-cell-style="{
        background: '#f0f2f5',
        color: '#333',
        height: '5px',
      }"
      size="mini"
      :height="490 + 'px'"
      :row-class-name="tableRowClassName"
      @select="handleRowSelect"
      @select-all="handleSelectAll"
      @selection-change="setSelectedRows"
    >
      <template slot="empty">
        <el-empty :image-size="120" :image="img" />
      </template>
      <el-table-column
        fixed="left"
        align="center"
        show-overflow-tooltip
        type="selection"
      />
      <el-table-column
        fixed="left"
        align="center"
        width="50"
        show-overflow-tooltip
        type="index"
        label="序号"
      />
      <el-table-column
        v-for="(col, index) in columns"
        :key="index"
        :type="col.type"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        show-overflow-tooltip
      >
        <!-- 通过 scoped slots 自定义单元格内容 -->
        <template #default="scope">
          <div v-if="col.prop === 'matchingNumber'">
            <el-input
              v-if="scope.row.isEditing"
              v-model="scope.row.matchingNumber"
              size="mini"
              placeholder="请输入匹配数量"
              @change="changeMatchingNumber(scope.row)"
              @blur="handleBlur(scope.row)"
            />
            <span v-else style="cursor: pointer" @click="toggleEdit(scope.row)">
              {{
                scope.row.matchingNumber != null
                  ? scope.row.matchingNumber
                  : "-"
              }}
            </span>
          </div>
          <span v-else-if="col.prop === 'matchingState'">
            <el-tag :type="matchingStateMap[scope.row.matchingState].type">
              {{ matchingStateMap[scope.row.matchingState].label }}
            </el-tag>
          </span>
          <el-tag
            v-else-if="col.prop === 'temporaryStatus'"
            :type="getTemporaryStatusTagType(scope.row[col.prop])"
          >
            {{ scope.row[col.prop] === 0 ? "非暂存" : "暂存" }}
          </el-tag>
          <span v-else>
            {{ scope.row[col.prop] != null ? scope.row[col.prop] : "-" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :page-sizes="pageSizes"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: "GrnTableList",
  components: {},
  data() {
    return {
      img: require("../../../assets/images/empty.png"),
      list: [],
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectedRows: [], // 存储勾选的表格数据
      elementLoadingText: "正在加载...",
      queryForm: {
        pageNo: 1,
        pageSize: 200,
      },
      pageSizes: [10, 30, 50, 100, 200],
      supplierOptions: [], // 供应商选项
      userId: "",
      columns: [], // 显示的列
      checkLabels: [], // 筛选列显示多选框数据
      checkList: [], // 筛选列数据

      matchingStateMap: {
        0: { label: "未匹配", type: "" },
        1: { label: "部分匹配", type: "warning" },
        2: { label: "完全匹配", type: "success" },
        "-1": { label: "匹配失败", type: "danger" },
      }, // 匹配状态
    };
  },

  computed: {
    selectedRowCount() {
      if (this.selectedRows.length === 0) return 0;
      return this.selectedRows.length;
    },
    totals() {
      if (this.selectedRows.length !== 0) {
        const { matchingNumber, matchingAmount } = this.calculateTotals(
          this.selectedRows
        );

        return {
          matchingNumber: matchingNumber || 0,
          matchingAmount: matchingAmount || 0,
        };
      }
      return {
        matchingNumber: 0,
        matchingAmount: 0,
      };
    },
  },

  watch: {
    "$store.state.InvoiceComparison.invoiceSelectedRow": {
      handler(newValue) {
        if (newValue.length === 0) {
          const grnTableRef = this.$refs["grnTableRef"];
          grnTableRef.clearSelection();
        }
      },
      deep: true, // 深度监听
    },
    "$store.state.InvoiceComparison.matching": {
      handler(newValue, oldValue) {
        console.log("matching newValue:", newValue);
        console.log("matching oldValue:", oldValue);
        const { grnSearchData } = this.$store.state.InvoiceComparison;
        this.fetchData(grnSearchData);
      },
      deep: true, // 深度监听
    },
  },

  created() {
    // console.log("链接带过来的query参数：", this.$route.query);
    // console.log("当前页路径：", window.location.href);
    // console.log("userId：", this.$route.query);
    this.userId = this.$route.query.userId;
    this.$nextTick(() => {
      this.fetchDefaultColumns();
      this.fetchSupplierList();
    });
  },

  updated() {
    this.$nextTick(() => {
      this.$refs["grnTableRef"].doLayout();
    });
  },

  methods: {
    /**
     * @description 勾选后计算勾选数据合计
     */
    calculateTotals(rows) {
      // 累加各项金额
      const totals = rows.reduce(
        (totals, item) => {
          const matchingNumber = parseFloat(item.matchingNumber) || 0;
          const matchingAmount = parseFloat(item.matchingAmount) || 0;

          totals.matchingNumber += matchingNumber;
          totals.matchingAmount += matchingAmount;
          return totals;
        },
        { matchingNumber: 0, matchingAmount: 0 }
      );

      // 在最终结果上进行四舍五入保留两位小数
      return {
        matchingNumber: parseFloat(totals.matchingNumber),
        matchingAmount: parseFloat(totals.matchingAmount.toFixed(2)),
      };
    },

    /**
     * @description 获取供应商名称
     */
    async fetchSupplierList() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/grn/supplierNameQuery", { userId: userId })
        .then((res) => {
          console.log("supplierOptions", res);
          this.supplierOptions = res.data;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
        });
    },

    /**
     * @description 获取默认列表筛选列内容
     */
    fetchDefaultColumns() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/invoiceCompareinvoiceCompare/getDefaultTableCells", {
          tableName: "compareGrn",
          userId: userId,
        })
        .then((res) => {
          this.checkLabels = JSON.parse(res.message).checkLabels;
          this.fetchCheckedColumns();
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
        });
    },

    /**
     * @description 获取勾选列表筛选列内容
     */
    fetchCheckedColumns() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/invoiceCompareinvoiceCompare/getCheckedTableCells", {
          tableName: "compareGrn",
          userId: userId,
        })
        .then((res) => {
          if (res.message !== "") {
            const checkedList = JSON.parse(res.message);
            this.checkList = checkedList.map((item) => item.prop);
            this.columns = [...checkedList];
          } else {
            this.setDefaultCheckListAndColumns();
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /**
     * @description 在组件加载时默认全选，并确保 columns 包含所有列
     */
    setDefaultCheckListAndColumns() {
      this.checkList = this.checkLabels.map((item) => item.prop);
      this.columns = [...this.checkLabels];
    },

    /**
     * @description 筛选列
     */
    handlerFilterFunc(type, currentItem = null) {
      if (type === "filter" && currentItem) {
        const { prop } = currentItem;
        const isChecked = event.target.checked;

        // 判断是选中还是取消选中
        if (isChecked) {
          // 选中，添加到最后
          this.checkList.push(prop);
          this.columns = [...this.columns, currentItem]; // 添加到最后
        } else {
          // 取消选中，使用 Set 优化过滤
          const propSet = new Set(this.checkList);
          propSet.delete(prop); // 直接删除目标项
          this.checkList = Array.from(propSet);

          const columnMap = new Map(
            this.columns.map((item) => [item.prop, item])
          );
          columnMap.delete(prop); // 移除目标项
          this.columns = Array.from(columnMap.values());
        }
      } else if (type === "allChecked") {
        // 全选
        this.checkList = this.checkLabels.map((item) => item.prop);
        this.columns = [...this.checkLabels];
      } else if (type === "cancel") {
        // 取消全选
        this.checkList = []; // 清空选中
        this.columns = []; // 清空列
      }
      this.saveDefaultColumns(this.columns);
    },

    /**
     * @description 更新列表筛选列内容
     */
    async saveDefaultColumns(rows) {
      this.$api
        .post("/input/invoiceCompareinvoiceCompare/updateTableCells", {
          tableName: "compareGrn",
          tableCellContent: JSON.stringify(rows),
          userId: this.userId,
        })
        .then(() => {})
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /**
     * @description 监听表格勾选变化
     */
    setSelectedRows(val) {
      this.selectedRows = val;
      this.$store.commit(
        "InvoiceComparison/GRN_SELECTED_ROW",
        this.$baseLodash.cloneDeep(val)
      );
    },

    /**
     * @description 监听表格单行勾选变化
     */
    handleRowSelect(selection, row) {
      // 判断当前行是否在选中数组中
      const isSelected = selection.includes(row);

      // 找到对应的列表项并处理逻辑
      const target = this.list.find((element) => element.id === row.id);
      if (target) {
        if (isSelected) {
          // 如果选中，设置 匹配数量 = 剩余可匹配数量；匹配金额 = GRN核算金额-已匹配金额
          target.matchingNumber = Number(target.noMatchingNum);
          target.matchingAmount = (
            Number(target.accountingAmount) -
            Number(target.accumulatedMatchingAmount)
          ).toFixed(2);
        } else {
          // 如果取消选中，重置 匹配数量和金额为0
          target.matchingNumber = 0;
          target.matchingAmount = 0;
        }
      }
    },

    /**
     * @description 全选
     */
    handleSelectAll(selection) {
      this.selectedRows = selection;

      // 判断是否全选
      const isSelectedAll = selection.length !== 0;

      // 定义更新匹配数据的函数
      const updateMatchingData = (element, isSelected) => {
        if (isSelected) {
          element.matchingNumber = Number(element.noMatchingNum);
          element.matchingAmount = (
            Number(element.accountingAmount) -
            Number(element.accumulatedMatchingAmount || 0)
          ).toFixed(2);
        } else {
          element.matchingNumber = 0;
          element.matchingAmount = 0;
        }
      };

      // 遍历列表，更新数据
      this.list.forEach((element) =>
        updateMatchingData(element, isSelectedAll)
      );

      this.$nextTick(() => {
        this.setSelectedRows(selection); // 手动调用 selection-change
      });
    },

    /**
     * @description 带状态表格
     */
    tableRowClassName({ row }) {
      if (row.noTaxMoney < 0) {
        return "warning-row";
      }
      return "";
    },

    /**
     * @description 获取列表数据
     */
    async fetchData(searchData) {
      this.listLoading = true;
      var params = Object.assign({ ...this.queryForm, ...searchData });

      params.userId = this.userId;
      this.$api
        .post(
          "/input/invoiceCompareinvoiceCompare/queryGrnInformationList",
          params
        )
        .then((res) => {
          this.listLoading = false;
          this.list = res.data.records.map((item) => ({
            ...item,
            isEditing: false, // 确保每个对象都有 isEditing 属性
          }));
          this.total = res.data.total;

          const { invoiceSelectedRow } = this.$store.state.InvoiceComparison;

          // 当发票列表选中行时，自动勾选对应批次号相同的grn数据
          this.$nextTick(() => {
            if (invoiceSelectedRow.length !== 0) {
              this.autoCheckFunc();
            }
          });
        })
        .catch((err) => {
          this.$message.error(err.message);
          this.listLoading = false;
        });
    },

    /**
     * @description 自动勾选批次号相同关联grn数据
     */
    autoCheckFunc() {
      const { invoiceSelectedRow } = this.$store.state.InvoiceComparison;
      const batchNo = invoiceSelectedRow[0].batchNo;
      const grnTableRef = this.$refs["grnTableRef"];

      this.list.forEach((item) => {
        if (item.batchNo === batchNo && item.temporaryStatus !== 0) {
          grnTableRef.toggleRowSelection(item, true);
        }
      });
    },

    /**
     * @description 改变列表每页显示条数
     */
    handleSizeChange(val) {
      const { grnSearchData } = this.$store.state.InvoiceComparison;
      grnSearchData.pageSize = val;
      this.fetchData(grnSearchData);
    },

    /**
     * @description 改变列表页数
     */
    handleCurrentChange(val) {
      const { grnSearchData } = this.$store.state.InvoiceComparison;
      grnSearchData.pageNo = val;
      this.fetchData(grnSearchData);
    },

    /**
     * @description 供应商名称点击后设置为编辑状态
     */
    toggleEdit(row) {
      row.isEditing = true;
    },

    /**
     * @description 数量改变事件
     */
    changeMatchingNumber(row) {
      // 选择后自动退出编辑状态
      row.isEditing = false;

      // 解析数据，确保数值类型有效
      const {
        matchingNumber = 0,
        noMatchingNum = 0,
        unitPrice = 0,
        accountingAmount = 0,
        accumulatedMatchingAmount = 0,
      } = row;

      const matchingNum = parseFloat(matchingNumber); // 匹配数量
      const noMatchNum = parseFloat(noMatchingNum) || 0; // 匹配金额
      const price = parseFloat(unitPrice) || 0; // 未税单价
      const accountAmt = parseFloat(accountingAmount) || 0; // 未税单价
      const accumulatedAmt = parseFloat(accumulatedMatchingAmount) || 0; // 已匹配金额

      // 根据条件计算匹配金额
      row.matchingAmount = parseFloat(
        matchingNum !== noMatchNum
          ? (matchingNum * price).toFixed(2)
          : (accountAmt - accumulatedAmt).toFixed(2)
      );

      this.$store.commit(
        "InvoiceComparison/GRN_SELECTED_ROW",
        this.$baseLodash.cloneDeep(this.selectedRows)
      );
    },

    /**
     * @description 失去焦点后退出编辑状态
     */
    handleBlur(row) {
      setTimeout(() => {
        row.isEditing = false;
        console.log("编辑已结束");
      }, 200); // 延迟 200ms，确保 change 先执行
    },

    /**
     * @description 根据 temporaryStatus 的状态返回不同的标签类型
     */
    getTemporaryStatusTagType(value) {
      // console.log("根据 temporaryStatus 的状态返回不同的标签类型", value);

      return value === 0 ? "info" : "warning";
    },

    /**
     * @description 暂存/取消暂存
     */
    async tempStorageFunc(type) {
      const { invoiceSelectedRow, grnSelectedRow } =
        this.$store.state.InvoiceComparison;

      if (invoiceSelectedRow.length === 0 || grnSelectedRow.length === 0) {
        this.$message.warning(
          invoiceSelectedRow.length === 0
            ? "未选中任何一条发票数据"
            : "未选中任何行一条GRN数据"
        );
        return false;
      }

      const params = {
        userId: this.userId,
        temporaryStatus: type,
        invoiceIds: invoiceSelectedRow.map((item) => item.id),
        grnIds: grnSelectedRow.map((item) => item.id),
        grnInformations: grnSelectedRow,
      };

      const res = await this.$api.post(
        "/input/invoiceCompareinvoiceCompare/noTaxMonyeDiff",
        params
      );

      this.$confirm(`未税金额差异为${res.data}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api
            .post(
              "/input/invoiceCompareinvoiceCompare/temporaryStorageIncomeInvoice",
              params
            )
            .then((result) => {
              this.$message.success(result.message);
              this.$store.commit("InvoiceComparison/TEMPORARY_OR_NOT", type);
              const { grnSearchData } = this.$store.state.InvoiceComparison;
              this.fetchData(grnSearchData);
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;

  .right-title {
    margin: 12px 10px 0 0;

    .sumTotal {
      font-size: 14px;
      font-weight: bold;
      color: #da5536;
    }
  }
}
::v-deep {
  .el-table .warning-row {
    color: red;
  }
  .el-table__empty-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-right: 100%;
  }
  .el-alert--warning.is-light {
    background-color: #ebf5ff;
    border: 1px solid #b2d6ff;
    color: #007aff;
  }
  .el-alert__title {
    color: #000000d9;
  }
}
</style>
