<template>
  <div class="table-container">
    <el-row style="margin-bottom: 10px" :span="24">
      <el-col :span="20">
        <el-button
          icon="el-icon-document-checked"
          type="primary"
          size="mini"
          @click="forcedMatchingFunc"
        >
          强制匹配
        </el-button>
        <el-button icon="el-icon-back" size="mini" @click="markBackFunc">
          标记退回
        </el-button>
        <el-button
          icon="el-icon-star-off"
          size="mini"
          @click="markNotDirectlySelectFunc"
        >
          标记非直采
        </el-button>
        <el-button
          icon="el-icon-edit-outline"
          size="mini"
          @click="manualMatchingFunc"
        >
          手工匹配
        </el-button>
        <el-button
          icon="el-icon-upload2"
          size="mini"
          @click="importInvoiceFunc"
        >
          导入发票
        </el-button>
      </el-col>
      <el-col :span="4" class="table-content-right">
        <el-popover placement="right" trigger="click" width="">
          <el-checkbox-group v-model="checkList">
            <template v-for="(item, i) in checkLabels">
              <el-checkbox
                :key="i"
                :label="item.prop"
                style="display: block; margin: 10px"
                @change="handlerFilterFunc('filter', item)"
              >
                {{ item.label }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
          <!-- 控制按钮 -->
          <el-button slot="reference" type="text" size="mini">
            <i class="el-icon-arrow-down el-icon-menu" /> 列筛选
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handlerFilterFunc('allChecked')"
          >
            全选
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handlerFilterFunc('cancel')"
          >
            取消全选
          </el-button>
        </el-popover>
      </el-col>
    </el-row>

    <el-alert
      style="margin-bottom: 10px"
      type="warning"
      show-icon
      :closable="false"
    >
      <template #title>
        已选发票数量：
        <span style="color: #fd6e0d; margin-right: 3px">
          {{ selectedRowCount }} </span
        >条 ， 数量合计：
        <span style="color: #fd6e0d; margin-right: 3px">
          {{ totals.goodsNum }} </span
        >个 ， 未税金额合计：
        <span style="color: #fd6e0d; margin-right: 3px">
          {{ totals.taxFreeAmount }} </span
        >元
      </template>
    </el-alert>

    <el-table
      ref="invTableRef"
      v-loading="listLoading"
      border
      :data="list"
      :element-loading-text="elementLoadingText"
      :header-cell-style="{
        background: '#f0f2f5',
        color: '#333',
        height: '5px',
      }"
      size="mini"
      :height="490 + 'px'"
      :row-class-name="tableRowClassName"
      @selection-change="setSelectedRows"
      @select="handleRowSelect"
      @select-all="handleSelectAll"
    >
      <template slot="empty">
        <el-empty :image-size="120" :image="img" />
      </template>
      <el-table-column
        fixed="left"
        align="center"
        show-overflow-tooltip
        type="selection"
      />
      <el-table-column
        fixed="left"
        align="center"
        width="50"
        show-overflow-tooltip
        type="index"
        label="序号"
      />
      <el-table-column
        v-for="(col, index) in columns"
        :key="index"
        :type="col.type"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        show-overflow-tooltip
      >
        <!-- 通过 scoped slots 自定义单元格内容 -->
        <template #default="scope">
          <span v-if="col.prop === 'supplierName'">
            <el-select
              v-if="scope.row.isEditing"
              v-model="scope.row.supplierName"
              filterable
              size="mini"
              placeholder="请选择"
              @change="changeSupplierName(scope.row)"
              @blur="handleBlur(scope.row)"
            >
              <el-option
                v-for="item in supplierOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <span
              v-else
              style="cursor: pointer"
              @click="toggleEdit(scope.row)"
              >{{ scope.row.supplierName || "-" }}</span
            >
          </span>
          <span v-else-if="col.prop === 'invoiceLine'">
            {{ formatColumn(scope.row, col.prop) }}
          </span>
          <span v-else-if="col.prop === 'compareStatus'">
            {{ formatColumn(scope.row, col.prop) }}
          </span>
          <el-tag
            v-else-if="col.prop === 'returnStatus'"
            :type="getReturnStatusTagType(scope.row[col.prop])"
          >
            {{ scope.row[col.prop] === 0 ? "未退回" : "已退回" }}
          </el-tag>
          <span v-else-if="col.prop === 'procureStatus'">
            {{ scope.row[col.prop] === 0 ? "非直采" : "直采" }}
          </span>
          <span v-else-if="col.prop === 'importStatus'">
            <el-tag :type="importStatusMap[scope.row.importStatus].type">
              {{ importStatusMap[scope.row.importStatus].label }}
            </el-tag>
          </span>
          <el-tag
            v-else-if="col.prop === 'temporaryStatus'"
            :type="getTemporaryStatusTagType(scope.row[col.prop])"
          >
            {{ scope.row[col.prop] === 0 ? "非暂存" : "暂存" }}
          </el-tag>
          <span v-else>
            {{ scope.row[col.prop] != null ? scope.row[col.prop] : "-" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :page-sizes="pageSizes"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <!-- 强制匹配 -->
    <forced-matching ref="forcedMatching" @fetch-data="fetchData" />
    <!-- 标记退回 -->
    <mark-back ref="markBack" @fetch-data="fetchData" />
    <!-- 标记非直采 -->
    <mark-not-directly-select
      ref="markNotDirectlySelect"
      @fetch-data="fetchData"
    />
    <!-- 导入发票 -->
    <input-invoice ref="inputInvoice" @fetch-data="fetchData" />
  </div>
</template>

<script>
import ForcedMatching from "./components/forcedMatching.vue";
import InputInvoice from "./components/inputInvoice.vue";
import MarkBack from "./components/markBack.vue";
import MarkNotDirectlySelect from "./components/markNotDirectlySelect.vue";

const invoiceLineEnum = {
  p: "普通发票（电票）",
  c: "普通发票（纸质）",
  s: "专用发票（纸质）",
  b: "专用发票（电子）",
  bs: "数电专票（电子）",
  pc: "数电普票（电子）",
  es: "数电专票（纸质）",
  ec: "数电普票（纸质）",
};
const compareStatusEnum = {
  0: "未匹配",
  1: "初始完全匹配成功",
  2: "包码完全匹配成功",
  3: "强制匹配成功",
  4: "退回匹配",
  5: "手工匹配",
  "-1": "初始匹配失败",
  "-2": "包码匹配失败",
  "-3": "数量匹配不成功",
  "-4": "未税金额匹配不成功",
};

export default {
  name: "InvoiceTableList",
  components: {
    ForcedMatching,
    MarkBack,
    MarkNotDirectlySelect,
    InputInvoice,
  },
  data() {
    return {
      img: require("../../../assets/images/empty.png"),
      list: [],
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectedRows: [], // 存储勾选的表格数据
      elementLoadingText: "正在加载...",
      queryForm: {
        pageNo: 1,
        pageSize: 200,
      },
      pageSizes: [10, 30, 50, 100, 200],
      supplierOptions: [], // 供应商选项
      userId: "",
      selectedInvoiceNumber: null, // 当前选中发票号码
      columns: [], // 显示的列
      checkList: [], // 筛选列数据
      checkLabels: [], // 筛选列显示多选框数据

      importStatusMap: {
        0: { label: "未导入", type: "" },
        1: { label: "导入", type: "success" },
        2: { label: "导入失败", type: "danger" },
        3: { label: "导入冲回", type: "warning" },
      }, // 导入状态
    };
  },

  computed: {
    selectedRowCount() {
      const { invoiceSelectedRow } = this.$store.state.InvoiceComparison;

      if (invoiceSelectedRow.length === 0) return 0;
      return invoiceSelectedRow.length;
    },
    totals() {
      const { invoiceSelectedRow } = this.$store.state.InvoiceComparison;

      if (invoiceSelectedRow.length !== 0) {
        const { goodsNum, taxFreeAmount } =
          this.calculateTotals(invoiceSelectedRow);

        return {
          goodsNum: goodsNum || 0,
          taxFreeAmount: taxFreeAmount || 0,
        };
      }
      return {
        goodsNum: 0,
        taxFreeAmount: 0,
      };
    },
  },

  watch: {
    "$store.state.InvoiceComparison.temporaryOrNot": {
      handler(_newValue, _oldValue) {
        // console.log("temporaryOrNot newValue:", newValue);
        // console.log("temporaryOrNot oldValue:", oldValue);
        const { invoiceSearchData } = this.$store.state.InvoiceComparison;
        this.fetchData(invoiceSearchData);
      },
      deep: true, // 深度监听
    },
  },

  created() {
    // console.log("链接带过来的query参数：", this.$route.query);
    // console.log("当前页路径：", window.location.href);
    // console.log("userId：", this.$route.query);
    this.userId = this.$route.query.userId;
    this.$nextTick(() => {
      this.fetchDefaultColumns();
      this.fetchSupplierList();
    });
  },

  updated() {
    this.$nextTick(() => {
      this.$refs["invTableRef"].doLayout();
    });
  },

  methods: {
    /**
     * @description 勾选后计算勾选数据合计
     */
    calculateTotals(rows) {
      // 累加各项金额
      const totals = rows.reduce(
        (totals, item) => {
          const goodsNum = parseFloat(item.goodsNum) || 0;
          const taxFreeAmount = parseFloat(item.taxFreeAmount) || 0;

          totals.goodsNum += goodsNum;
          totals.taxFreeAmount += taxFreeAmount;
          return totals;
        },
        { goodsNum: 0, taxFreeAmount: 0 }
      );

      // 在最终结果上进行四舍五入保留两位小数
      return {
        goodsNum: parseFloat(totals.goodsNum),
        taxFreeAmount: parseFloat(totals.taxFreeAmount.toFixed(2)),
      };
    },

    /**
     * @description 获取供应商名称
     */
    fetchSupplierList() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/grn/supplierNameQuery", { userId: userId })
        .then((res) => {
          console.log("supplierOptions", res);
          this.supplierOptions = res.data;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
        });
    },

    /**
     * @description 获取默认列表筛选列内容
     */
    fetchDefaultColumns() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/invoiceCompareinvoiceCompare/getDefaultTableCells", {
          tableName: "compareInvoice",
          userId: userId,
        })
        .then((res) => {
          this.checkLabels = JSON.parse(res.message).checkLabels;
          this.fetchCheckedColumns();
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
        });
    },

    /**
     * @description 获取勾选列表筛选列内容
     */
    fetchCheckedColumns() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/invoiceCompareinvoiceCompare/getCheckedTableCells", {
          tableName: "compareInvoice",
          userId: userId,
        })
        .then((res) => {
          if (res.message !== "") {
            const checkedList = JSON.parse(res.message);
            this.checkList = checkedList.map((item) => item.prop);
            this.columns = [...checkedList];
          } else {
            this.setDefaultCheckListAndColumns();
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /**
     * @description 在组件加载时默认全选，并确保 columns 包含所有列
     */
    setDefaultCheckListAndColumns() {
      this.checkList = this.checkLabels.map((item) => item.prop);
      this.columns = [...this.checkLabels];
    },

    /**
     * @description 筛选列
     */
    handlerFilterFunc(type, currentItem = null) {
      if (type === "filter" && currentItem) {
        const { prop } = currentItem;
        const isChecked = event.target.checked;

        // 判断是选中还是取消选中
        if (isChecked) {
          // 选中，添加到最后
          this.checkList.push(prop);
          this.columns = [...this.columns, currentItem]; // 添加到最后
        } else {
          // 取消选中，使用 Set 优化过滤
          const propSet = new Set(this.checkList);
          propSet.delete(prop); // 直接删除目标项
          this.checkList = Array.from(propSet);

          const columnMap = new Map(
            this.columns.map((item) => [item.prop, item])
          );
          columnMap.delete(prop); // 移除目标项
          this.columns = Array.from(columnMap.values());
        }
      } else if (type === "allChecked") {
        // 全选
        this.checkList = this.checkLabels.map((item) => item.prop);
        this.columns = [...this.checkLabels];
      } else if (type === "cancel") {
        // 取消全选
        this.checkList = []; // 清空选中
        this.columns = []; // 清空列
      }
      this.saveDefaultColumns(this.columns);
    },

    /**
     * @description 更新列表筛选列内容
     */
    async saveDefaultColumns(rows) {
      this.$api
        .post("/input/invoiceCompareinvoiceCompare/updateTableCells", {
          tableName: "compareInvoice",
          tableCellContent: JSON.stringify(rows),
          userId: this.userId,
        })
        .then(() => {})
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /**
     * @description 监听表格勾选变化
     */
    setSelectedRows(selection) {
      // 更新当前选中的行
      this.selectedRows = selection;
      this.$store.commit(
        "InvoiceComparison/INVOICE_SELECTED_ROW",
        this.$baseLodash.cloneDeep(selection)
      );
    },

    /**
     * @description 监听表格单行勾选变化
     */
    handleRowSelect(selection, row) {
      // 选中/取消选中一行时触发
      const selectedInvoiceNumber = row.invoiceNumber;

      if (selection.includes(row)) {
        // 如果是选中，找到所有相同发票号码的行并选中
        const rowsWithSameInvoice = this.list.filter(
          (item) => item.invoiceNumber === selectedInvoiceNumber
        );
        // 使用 $nextTick 来延迟 toggleRowSelection
        this.$nextTick(() => {
          rowsWithSameInvoice.forEach((item) => {
            this.$refs["invTableRef"].toggleRowSelection(item, true);
          });
        });
      } else {
        // 如果是取消选中，找到所有相同发票号码的行并取消选中
        const rowsWithSameInvoice = this.list.filter(
          (item) => item.invoiceNumber === selectedInvoiceNumber
        );
        // 使用 $nextTick 来延迟 toggleRowSelection
        this.$nextTick(() => {
          rowsWithSameInvoice.forEach((item) => {
            this.$refs["invTableRef"].toggleRowSelection(item, false);
          });
        });
      }
    },

    /**
     * @description 全选
     */
    handleSelectAll(selection) {
      // 如果是全选或取消全选，不做额外处理，直接使用原生全选功能
      this.selectedRows = selection;
    },

    /**
     * @description 带状态表格
     */
    tableRowClassName({ row }) {
      if (row.invoiceType === 2 || row.invoiceState === 3) {
        return "warning-row";
      }
      return "";
    },

    /**
     * @description 获取列表数据
     */
    async fetchData(searchData) {
      this.listLoading = true;
      var params = Object.assign({ ...this.queryForm, ...searchData });

      params.userId = this.userId;
      this.$api
        .post(
          "/input/invoiceCompareinvoiceCompare/queryIncomePoolInvoiceAndDetail",
          params
        )
        .then((res) => {
          console.log("res", res);
          this.listLoading = false;
          this.list = res.data.records.map((item) => ({
            ...item,
            isEditing: false, // 确保每个对象都有 isEditing 属性
          }));
          this.total = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.message);
          this.listLoading = false;
        });
    },

    /**
     * @description 改变列表每页显示条数
     */
    handleSizeChange(val) {
      const { invoiceSearchData } = this.$store.state.InvoiceComparison;
      invoiceSearchData.pageSize = val;
      this.fetchData(invoiceSearchData);
    },

    /**
     * @description 改变列表页数
     */
    handleCurrentChange(val) {
      const { invoiceSearchData } = this.$store.state.InvoiceComparison;
      invoiceSearchData.pageNo = val;
      this.fetchData(invoiceSearchData);
    },

    /**
     * @description 供应商名称点击后设置为编辑状态
     */
    toggleEdit(row) {
      row.isEditing = true;
    },

    /**
     * @description 供应商名称改变事件
     */
    changeSupplierName(row) {
      row.isEditing = false; // 选择后自动退出编辑状态
      this.saveEdit(row);
    },

    /**
     * @description 保存新选择的供应商名称
     */
    saveEdit(row) {
      console.log("保存编辑数据:", row);
      const params = {
        userId: this.userId,
        invoiceId: row.id,
        upSupplierName: row.supplierName,
      };

      this.$api
        .post(
          "/input/invoiceCompareinvoiceCompare/updateInvoiceSaleNum",
          params
        )
        .then(() => {
          const { invoiceSearchData } = this.$store.state.InvoiceComparison;
          this.fetchData(invoiceSearchData);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /**
     * @description 失去焦点后退出编辑状态
     */
    handleBlur(row) {
      setTimeout(() => {
        row.isEditing = false;
        console.log("编辑已结束");
      }, 200); // 延迟 200ms，确保 change 先执行
    },

    /**
     * @description 根据 returnStatus 的状态返回不同的标签类型
     */
    getReturnStatusTagType(value) {
      // console.log("根据 returnStatus 的状态返回不同的标签类型", value);

      return value === 0 ? "success" : "danger";
    },

    /**
     * @description 根据 temporaryStatus 的状态返回不同的标签类型
     */
    getTemporaryStatusTagType(value) {
      // console.log("根据 temporaryStatus 的状态返回不同的标签类型", value);

      return value === 0 ? "info" : "warning";
    },

    /**
     * @description 格式化列表内容
     */
    formatColumn(row, column) {
      if (column === "invoiceLine") {
        return invoiceLineEnum[row.invoiceLine]; // 根据枚举映射显示
      }
      if (column === "compareStatus") {
        return compareStatusEnum[row.compareStatus]; // 根据枚举映射显示
      }
      return row[column]; // 默认返回原始值
    },

    /**
     * @description 强制匹配
     */
    forcedMatchingFunc() {
      const { invoiceSelectedRow } = this.$store.state.InvoiceComparison;
      const { grnSelectedRow } = this.$store.state.InvoiceComparison;
      if (invoiceSelectedRow.length === 0) {
        this.$message.warning("未选中任何一条发票数据");
        return false;
      }
      if (grnSelectedRow.length === 0) {
        this.$message.warning("未选中任何行一条GRN数据");
        return false;
      }
      this.$refs["forcedMatching"].showMatchingDialog(
        invoiceSelectedRow,
        grnSelectedRow
      );
    },

    /**
     * @description 标记退回
     */
    markBackFunc() {
      const { invoiceSelectedRow } = this.$store.state.InvoiceComparison;
      if (invoiceSelectedRow.length === 0) {
        this.$message.warning("未选中任何一条发票数据");
        return false;
      }
      this.$refs["markBack"].showBackDialog(this.selectedRows);
    },

    /**
     * @description 标记非直采
     */
    markNotDirectlySelectFunc() {
      if (this.selectedRows.length === 0) {
        this.$message.warning("未选中任何一条发票数据");
        return false;
      }
      this.$refs["markNotDirectlySelect"].showMarkDialog(this.selectedRows);
    },

    /**
     * @description 手工匹配
     */
    manualMatchingFunc() {
      const { invoiceSelectedRow } = this.$store.state.InvoiceComparison;
      const { grnSelectedRow } = this.$store.state.InvoiceComparison;
      if (invoiceSelectedRow.length === 0) {
        this.$message.warning("未选中任何一条发票数据");
        return false;
      }
      if (grnSelectedRow.length === 0) {
        this.$message.warning("未选中任何行一条GRN数据");
        return false;
      }
      const params = {
        userId: this.userId,
        invoiceIds: invoiceSelectedRow.map((item) => item.id),
        grnInformations: grnSelectedRow,
      };

      this.$api
        .post("/input/invoiceCompareinvoiceCompare/handMatch", params)
        .then((res) => {
          this.$message.success(res.message);
          this.$store.commit("InvoiceComparison/MATCHING", true);
          const { invoiceSearchData } = this.$store.state.InvoiceComparison;
          this.fetchData(invoiceSearchData);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    /**
     * @description 导入发票
     */
    importInvoiceFunc() {
      this.$refs["inputInvoice"].showImportDialog();
    },

    /**
     * @description 筛选列
     */
    filterColumn() {
      this.$refs["filterColumn"].showEdit();
    },

    /**
     * @description 加载筛选好的列表字段
     */
    getNewFilters(data) {
      this.columns = [];
      for (const iterator of this.tableList) {
        for (const iter of data) {
          if (iterator.label === iter) {
            this.columns.push(iterator);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  border-right: 1px solid#e3e8e9;
}
::v-deep {
  .el-table .warning-row {
    color: red;
  }
  .el-table__empty-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-right: 100%;
  }
  .el-alert--warning.is-light {
    background-color: #ebf5ff;
    border: 1px solid #b2d6ff;
    color: #007aff;
  }
  .el-alert__title {
    color: #000000d9;
  }
}
</style>
